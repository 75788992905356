<script>
export default {
  name: 'Slider',
  props: {
    items: {
      type: Array
    }
  }
}
</script>
<template>
  <v-slide-group class="pr-2 pt-8" show-arrows>
    <template v-slot:next="{on, attrs}">
      <v-btn class="slider-button slider-button--next" v-bind="attrs" v-on="on"><v-icon class="slider-button--icon">mdi-chevron-right</v-icon></v-btn>
    </template>
    <template v-slot:prev="{on, attrs}">
      <v-btn class="slider-button slider-button--prev" v-bind="attrs" v-on="on"><v-icon class="slider-button--icon">mdi-chevron-left</v-icon></v-btn>
    </template>
    <v-slide-item v-for="item in items" :key="`${item && item.id}${Math.random()}`">
      <slot :item="item"></slot>
    </v-slide-item>
  </v-slide-group>
</template>
<style lang="scss">
.slider-button {
  position: absolute;
  top: -0.5rem;
  right: 1rem;
}
.slider-button--prev {
  right: 4.5rem;
}
.slider-button.v-btn.slider-button--prev,
.slider-button.v-btn.slider-button--next {
  width: 40px;
  height: 40px;
  min-width: 40px;
}
.v-slide-group__next, .v-slide-group__prev {
  min-width: 0;
  flex: 0;
}

.slider-button.slider-button--prev.v-btn,
.slider-button.slider-button--next.v-btn {
  background-color: white;
}
.v-btn .v-icon.slider-button--icon {
  color: $neutral-black;
}
.v-slide-group__prev--disabled > .v-btn .v-icon.slider-button--icon,
.v-slide-group__next--disabled > .v-btn .v-icon.slider-button--icon {
  background-color: $neutral-light;
}
.v-slide-group__prev--disabled > .slider-button.slider-button--prev.v-btn,
.v-slide-group__next--disabled > .slider-button.slider-button--next.v-btn {
  opacity: 0.5;
  background-color: $neutral-light;
}
</style>
